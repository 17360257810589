import React, {FC} from 'react';
import {NextPageContext} from 'next';
import Router from 'next/router';

import styles from './styles';

export interface ErrorPageComponentProps {
  statusCode?: number;
}

export interface ErrorPageContext extends NextPageContext {
  err?: {statusCode?: number} & Error;
}

const COMPONENT_NAME = 'error-page';

const ErrorPageComponent: FC<ErrorPageComponentProps> = ({statusCode}) => {
  const errorMessage =
    statusCode === 404
      ? 'This page could not be found.'
      : 'An error occurred on server.';

  return (
    <div className={COMPONENT_NAME}>
      <div
        className={`${COMPONENT_NAME}__image-container`}
        style={{backgroundImage: 'url("/static/img/onboarding/NoMatch.jpg")'}}
      />

      <div className={`${COMPONENT_NAME}__message-container`}>
        <p className={`${COMPONENT_NAME}__message-container_message`}>
          {statusCode
            ? `${statusCode} | ${errorMessage}`
            : 'An error occurred on client.'}
        </p>
        <a
          className={`${COMPONENT_NAME}__message-container_link`}
          onClick={() => Router.push('/')}>
          {' '}
          Back to home{' '}
        </a>
      </div>
      <style jsx> {styles} </style>
    </div>
  );
};

ErrorPageComponent.defaultProps = {
  statusCode: undefined,
};

export default ErrorPageComponent;
