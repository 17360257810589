import css from 'styled-jsx/css';

const pagesStyles = css`
  .error-page {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 35% 1fr;

    &__image-container {
      height: 50vh;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__message-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 50px;

      &_message {
        font-size: 20px;
      }

      &_link {
        margin-top: 10px;
        color: #4a63bc;
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: 764px) {
    .error-page {
      grid-template-columns: 35% 65%;
      grid-template-rows: 1fr;

      &__image-container {
        height: 100%;
      }

      &__message-container {
        &_message {
          font-size: 25px;
        }
      }
    }
  }
`;

export default pagesStyles;
